import classes from './ServiceIconList.module.css';
function ServicesIconList(props) {
  return (
    <div className={classes.ServicesIconList}>
      {Array.from({ length: props.offsetIconIndex }).map((_, index) => {
        const circularIndex =
          (props.iconIndex + index) % props.servicesData.length;
        const isTransitioning =
          props.transitionDirection !== null &&
          props.transitionIndex === circularIndex;

        return (
          <div
            key={props.servicesData[circularIndex].id}
            className={`${classes.ServiceIcon} ${
              isTransitioning
                ? props.transitionDirection === 'left'
                  ? classes.transitionEnterFromRight
                  : props.transitionDirection === 'right'
                  ? classes.transitionEnterFromLeft
                  : ''
                : ''
            } ${props.transitionDirection && classes.transitionEnter}  ${
              props.servicesData[circularIndex].id === props.serviceId &&
              classes.selected
            }`}
            onClick={() => {
              props.handleServiceChange(circularIndex);
            }}
          >
            <p>{props.servicesData[circularIndex].title}</p>
            <div className={classes.Icon}>
              <img
                src={props.servicesData[circularIndex].icon}
                alt={props.servicesData[circularIndex].title}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default ServicesIconList;
