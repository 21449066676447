import classes from './HomePage.module.css';
import Welcome from '../Components/PageComponent/Welcome/Welcome.js';
import WhyUs from '../Components/PageComponent/WhyUs/WhyUs.js';
import AllServices from '../Components/PageComponent/AllServices/AllServices.js';
import Footer from '../Components/PageComponent/Footer/Footer.js';
import Loader from '../Components/OtherComponents/Loader/Loader.js';

import { ServiceIdContext } from '../Context/service-id-context.js';

import { useRef, useEffect, useState } from 'react';

import * as images from '../Assets/index.js';

function HomePage() {
  const welcomeRef = useRef(null);
  const whyUsRef = useRef(null);
  const allServicesRef = useRef(null);

  const [serviceID, setServiceID] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const scrollToRef = ref => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToRef(welcomeRef);
  }, []);

  useEffect(() => {
    // Create an array of all image paths
    const imagePaths = Object.values(images);

    // Counter to track the number of loaded images
    let loadedImageCount = 0;

    // Function to handle image load
    const handleImageLoad = () => {
      loadedImageCount++;
      // Check if all images are loaded
      if (loadedImageCount === imagePaths.length) {
        // Set loading to false after all images are loaded
        setIsLoading(false);
      }
    };

    // Load each image dynamically
    imagePaths.forEach(src => {
      const img = new Image();

      img.src = src;
      img.onload = handleImageLoad;
    });

    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <ServiceIdContext.Provider value={serviceID}>
      <div className={classes.Body}>
        {isLoading ? (
          <div className={classes.Loader}>
            <Loader />
          </div>
        ) : (
          <>
            {' '}
            <Welcome
              welcomeRef={welcomeRef}
              scrollToWhyUsRef={() => scrollToRef(whyUsRef)}
              scrollToAllServices={() => scrollToRef(allServicesRef)}
            />
            <WhyUs whyUsRef={whyUsRef} />
            <AllServices allServicesRef={allServicesRef} />
            <Footer
              scrollToServiceDetailsRef={id => {
                scrollToRef(allServicesRef);
                setServiceID(id);
              }}
            />
          </>
        )}
      </div>
    </ServiceIdContext.Provider>
  );
}

export default HomePage;
