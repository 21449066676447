import classes from './Loader.module.css';
function Loader() {
  return (
    <div className={classes.LdsDefault}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
export default Loader;
