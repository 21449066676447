import classes from './WhyUs.module.css';
import { WhyUsData } from '../../../Data/Data';
import { useState, useEffect } from 'react';
function WhyUs(props) {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    const currentText = WhyUsData[currentTextIndex].text;
    let index = 0;

    const typeText = () => {
      if (index <= currentText.length) {
        setDisplayedText(currentText.substring(0, index));
        index += 1;
        setTimeout(typeText, 50); // Adjust the typing speed here
      } else {
        setTimeout(() => {
          // Start backspacing simulation
          let backspaceIndex = currentText.length;

          const backspace = () => {
            if (backspaceIndex >= 0) {
              setDisplayedText(currentText.substring(0, backspaceIndex));
              backspaceIndex -= 1;
              setTimeout(backspace, 20); // Adjust the backspacing speed here
            } else {
              // Move to the next text after backspacing
              setCurrentTextIndex(
                prevIndex => (prevIndex + 1) % WhyUsData.length
              );
            }
          };

          backspace();
        }, 1000); // Delay before transitioning to the next text
      }
    };

    typeText();
  }, [currentTextIndex]);
  return (
    <div className={classes.Body} ref={props.whyUsRef}>
      <div className={classes.ContentBox}>
        <p>لماذا هنا الإعلان ؟</p>
        <p className={classes.ContentText}>{displayedText}</p>
      </div>
    </div>
  );
}
export default WhyUs;
