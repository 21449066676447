import classes from './Footer.module.css';
import { logo } from '../../../Assets';

import { servicesData, SocialMedia } from '../../../Data/Data';
function Footer(props) {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className={classes.Footer} ref={props.FooterRef}>
      <div className={classes.FooterSlogan}>
        <img
          src={logo}
          alt='Logo'
          className={classes.FooterSloganLogo}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        />
        <p className={classes.FooterSloganText}>
          روّاد في عالم التسويق الرقمي. نقدم حلول فعّالة لتعزيز تواجدك الرقمي
          بأسلوب مبتكر.
        </p>
      </div>
      <span className={classes.BorderLine}></span>
      <div className={classes.FooterServicesListContainer}>
        <div className={classes.FooterServicesList}>
          <p className={classes.FooterServicesListTitle}>الخدمات</p>
          <div className={classes.FooterServicesListItems}>
            {servicesData.length > 0 &&
              servicesData.map(data => {
                return (
                  <p
                    key={data.id}
                    className={classes.FooterServicesListItem}
                    onClick={() => {
                      props.scrollToServiceDetailsRef(data.id);
                    }}
                  >
                    {data.title}
                  </p>
                );
              })}
          </div>
        </div>
      </div>
      <div className={classes.FooterContactInfo}>
        <div className={classes.TopContent}>
          <p className={classes.FooterContactInfoTitle}>تواصل معنا</p>
          <div className={classes.FooterContactInfoList}>
            {SocialMedia.map(data => {
              return (
                <a
                  href={data.link}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={classes.FooterContactInfoListItem}
                >
                  <p className={classes.FooterContactInfoListItemText}>
                    {data.title}
                  </p>
                  <img
                    src={data.iconLight}
                    alt={data.title}
                    className={classes.FooteContactInfoListItemIcon}
                  />
                </a>
              );
            })}
          </div>
        </div>
        <p className={classes.FooterCopyrightText}>
          جميع الحقوق محفوظه لدي شركة
          <span
            className={classes.Highlighted}
            onClick={() => {
              handleScrollToTop();
            }}
          >
            {' '}
            هنا الإعلان
          </span>
          <span className={classes.SmallSec}> &copy; </span>
        </p>
      </div>
    </div>
  );
}
export default Footer;
