import classes from './ServiceDetails.module.css';
import { servicesData } from '../../../Data/Data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
function ServiceDetails(props) {
  return (
    <div className={`${classes.ServiceDetails} `}>
      {/* slideShow */}
      {/* <div className={classes.SlideShow}>
        <img src={servicesData[props.serviceId - 1].slideShow} alt='' />
      </div> */}
      <div className={classes.DetailsSection}>
        {/* img */}
        <div className={classes.TextBox}>
          <div className={classes.Title}>
            <h1>{servicesData[props.serviceId - 1].title}</h1>
          </div>
          {/* description */}
          <div className={classes.ServiceDescription}>
            <p>{servicesData[props.serviceId - 1].description}</p>
          </div>
          {/* breakPoints */}
          <div className={classes.BreakPoints}>
            {servicesData[props.serviceId - 1].breakPoints.map(breakPoint => (
              <div className={classes.BreakPointsItem} key={breakPoint.title}>
                <FontAwesomeIcon icon={faCaretLeft} />
                <p>{breakPoint.title}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.GeneralImage}>
          <img
            src={servicesData[props.serviceId - 1].generalImage}
            alt={servicesData[props.serviceId - 1].title}
          />
        </div>
      </div>
    </div>
  );
}

export default ServiceDetails;
