import classes from './IconListPreviewCircles.module.css';
function IconListPreviewCircles({
  servicesData,
  iconIndex,
  offsetIconIndex,
  selectedServiceIndex,
}) {
  return (
    <div className={classes.IconListPreviewCircles}>
      {servicesData.map((_, index) => {
        const isActive = isCircleActive(
          index,
          servicesData.length,
          iconIndex,
          offsetIconIndex
        );
        const isSelected = index === selectedServiceIndex;
        return (
          <div
            key={index}
            className={`${classes.Circle} ${isActive ? classes.Active : ''} ${
              isSelected ? classes.Selected : ''
            }`}
          ></div>
        );
      })}
    </div>
  );
}

function isCircleActive(circleIndex, totalCircles, iconIndex, offsetIconIndex) {
  const endOfRange = (iconIndex + offsetIconIndex - 1) % totalCircles;

  if (iconIndex + offsetIconIndex <= totalCircles) {
    // If the range doesn't exceed the total number of circles
    return circleIndex >= iconIndex && circleIndex <= endOfRange;
  } else {
    // If the range exceeds the total number of circles, handle it separately
    return (
      (circleIndex >= iconIndex && circleIndex <= totalCircles - 1) ||
      (circleIndex >= 0 && circleIndex <= endOfRange)
    );
  }
}

export default IconListPreviewCircles;
