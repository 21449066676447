import HomePage from './Pages/HomePage';
function App() {
  return (
    <div>
      <HomePage />
    </div>
  );
}

export default App;
