import {
  tiktokLightIcon,
  facebookLightIcon,
  instagramLightIcon,
  whatsappLightIcon,
  emailIcon,
  mobileIcon,
  Advertising,
  MarketingStrategy,
  Photography,
  SocialMediaImage,
  WebAppliction,
  VisualIdentity,
  CompanyWorkHistory,
  AdvertisingIcon,
  MarketingStrategyIcon,
  PhotographyIcon,
  SocialMediaIcon,
  WebApplicationIcon,
  VisualIdentityIcon,
  CompanyWorkHistoryIcon,
} from '../Assets/index.js';

const WelcomeMessage = {
  heading: 'تسويق رقمي مبتكر',
  paragraph: `إستعد لتحقيق النجاح المستدام في عالم التسويق الرقمي
  نقدم لك حلولًا شاملة ومخصصة تتناسب مع إحتياجاتك ومتطلباتك`,
};

const WhyUsData = [
  {
    id: 1,
    text: ' نسعى جاهدين لتحقيق أهدافكم من خلال إستراتيجيات متطورة وتصميم هويات رقمية تعكس جوهر عملكم،',
  },
  {
    id: 2,
    text: ' نقدم لكم تجربة تسويقية فريدة، حيث نجمع بين الإبداع والتقنية لتعزيز وجودكم الرقمي',
  },
  {
    id: 3,
    text: 'نتفانى في تحليل إحتياجاتكم وابتكار حلول مخصصة تعبر عن روح علامتكم التجارية',
  },
];

const SocialMedia = [
  {
    id: 1,
    title: 'فيسبوك',
    link: 'https://www.facebook.com/profile.php?id=61552994350549',
    iconLight: facebookLightIcon,
  },
  {
    id: 2,
    title: 'إنستجرام',
    link: 'https://www.instagram.com/hona.ele3lan/',
    iconLight: instagramLightIcon,
  },
  {
    id: 3,
    title: 'تيك توك',
    link: 'https://www.tiktok.com/@hona_ele3lan',
    iconLight: tiktokLightIcon,
  },
  {
    id: 4,
    title: 'واتساب',
    link: 'https://wa.me/201070011870',
    iconLight: whatsappLightIcon,
  },
];
const ContactInfo = [
  {
    id: 1,
    title: 'الهاتف',
    content: '01070011870',
    path: 'tel:01070011870',
    icon: mobileIcon,
  },
  {
    id: 2,
    title: 'البريد الإلكتروني',
    content: 'info@Hona-aelan.com',
    path: 'mailto:info@Hona-aelan.com',
    icon: emailIcon,
  },
];
const servicesIntro = {
  title: 'خدماتنا',
  description:
    'إستمتع بمجموعة متنوعة من الخدمات الرقمية المصممة خصيصًا لدفع عملك نحو النجاح. إكتشف حلولًا فريدة تلبي إحتياجات علامتك التجارية وتضمن وجودًا فعّالًا وجذابًا على الإنترنت',
};
const servicesData = [
  {
    id: 1,
    title: 'الهوية البصرية',
    icon: VisualIdentityIcon,
    slideShow: VisualIdentity,
    generalImage: VisualIdentity,
    description: `الهوية البصرية هي العنصر الأساسي لأي شركة أو علامة تجارية. 
    إنها تمثل الشخصية والهوية للعلامة التجارية، وتشمل العناصر المرئية التي تميزها وتجعلها مميزة وسهلة التعرف عليها.`,
    breakPoints: [
      {
        title: `تشمل الهوية البصرية عادة الشعار (Logo)، والألوان، والخطوط، والأيقونات، والأنماط التصميمية. 
      يتم إستخدام هذه العناصر بشكل متكرر في جميع مواد العلامة التجارية، مثل الموقع الإلكتروني، والمطبوعات، والإعلانات، وحتى في العبوات والمنتجات.`,
      },
    ],
  },
  {
    id: 2,
    title: 'سوشيال ميديا',
    icon: SocialMediaIcon,
    slideShow: SocialMediaImage,
    generalImage: SocialMediaImage,
    description:
      'تحتاج إستراتيجية وسائل التواصل الاجتماعي إلى تنوع في المحتوى والتفاعل مع المتابعين لبناء تفاعل إيجابي وبناء علاقات قوية مع الجمهور..',
    breakPoints: [
      { title: 'كتابة محتوى جذاب ومُبتكر للجمهور المُستهدف' },
      { title: 'ديزاين بفكرة يُعبر عن محتواك بشكل مُلفت' },
      { title: 'موشن جرافيك ابتكاري بأداء صوتي متميز' },
      { title: 'مقاطع ريلز لجذب جمهور جديد ومتفاعل' },
    ],
  },
  {
    id: 3,
    title: 'الخطة التسويقية',
    icon: MarketingStrategyIcon,
    slideShow: MarketingStrategy,
    generalImage: MarketingStrategy,
    description: `الخطة التسويقية 
     هي وثيقة إستراتيجية تحدد الأهداف والإستراتيجيات و الخطوات التي ستستخدمها شركة أو منظمة لتعزيز منتجاتها أو خدماتها وزيادة حصتها في السوق. 
     `,
    breakPoints: [
      {
        title: `تهدف الخطة التسويقية الى تحقيق النمو والربحية وتعزيز الوعي بالعلامة التجارية وتحقيق المزيد من المبيعات.`,
      },
      {
        title: `تتضمن الخطة التسويقية تحليلًا شاملاً للسوق والعملاء المستهدفين والمنافسة والإتجاهات الصناعية يتم إستخدام هذه المعلومات لتحديد إستراتيجيات التسويق التي ستساعد في تحقيق الأهداف المحددة. `,
      },
    ],
  },
  {
    id: 4,
    title: 'التصوير (صور-فيديو)',
    icon: PhotographyIcon,
    slideShow: Photography,
    generalImage: Photography,
    description:
      'أكثر وسيلة فعالة لنقل الرسائل وجذب الإنتباه وزيادة التفاعل بشكل سريع وبسيط..',
    breakPoints: [
      { title: 'تصوير المنتجات والخدمات بطريقة إحترافية' },
      { title: `مساعدتك في جعل التصوير يعبر عن منتجك` },
      { title: 'كيفية جذب العميل من خلال الصور والفيديوهات' },
    ],
  },
  {
    id: 5,
    title: 'المواقع الإلكترونية',
    icon: WebApplicationIcon,
    slideShow: WebAppliction,
    generalImage: WebAppliction,
    description: `الموقع الإلكتروني هو واحد من أهم الأدوات التسويقية والتواصلية المتاحة في العصر الرقمي. 
     يعد الموقع الإلكتروني واجهة رقمية تمكن الشركة من التفاعل مع العملاء المحتملين والحاليين على مستوى عالمي.`,
    breakPoints: [
      {
        title: ` يعتبر الموقع الإلكتروني يسمح للشركة بأن تكون متاحة للعملاء الوصول الى معلومات الشركة وخدماتها في أي وقت من أي مكان.`,
      },
      {
        title: `يساهم في بناء الثقة والمصداقية عندما يجد العملاء موقعًا محترفًا ومعلومات شاملة ومحدثة
.`,
      },
      {
        title: `يوفر وسيلة فعالة للتسويق والترويج أي يمكن إستخدام الموقع لعرض المنتجات والخدمات والعروض الترويجية.`,
      },
      {
        title: `يوفر وسيلة للتواصل المباشر مع العملاء، يمكن إضافة نماذج إتصال أو دردشة حية أو عناوين بريد الكتروني للسماح للعملاء بالتواصل المباشر.`,
      },
    ],
  },
  {
    id: 6,
    title: 'إعلانات ممولة ',
    icon: AdvertisingIcon,
    slideShow: Advertising,
    generalImage: Advertising,
    description:
      'تقدم الإعلانات الممولة فرصة فعالة لتحسين أداء حسابات وسائل التواصل الإجتماعي  وتحقيق أهداف التسويق والتفاعل مع الجمهور..',
    breakPoints: [
      { title: `لتحقيق الهدف المستهدف` },
      {
        title:
          'التعامل مع أكثر من منصة إعلانية (فيس بوك - إنستا - تويتر - تيك توك - يوتيوب)',
      },
      { title: 'إظهار اسم الشركة في محركات البحث المختلفة SEM (Google Ads)' },
      { title: 'عمل جروبات ورسائل جماعية من خلال واتساب وتليغرام' },
      { title: 'إضافة موقع الشركة علي google maps' },
    ],
  },
  {
    id: 7,
    title: 'سابقة أعمال شركتك ',
    icon: CompanyWorkHistoryIcon,
    slideShow: CompanyWorkHistory,
    generalImage: CompanyWorkHistory,
    description: `تعد سابقة الأعمال أداة قوية لإظهار قدرات الشركة ومهاراتها في تنفيذ المشاريع فهي تقدم دليلًا ملموسًا على قدرة الشركة على تلبية إحتياجات العملاء وتحقيق نتائج مرضية.
     يمكن أن تشمل سابقة الأعمال عينات من المنتجات أو الخدمات المقدمة، والتقارير والدراسات التي تم إنجازها، والشهادات أو الجوائز التي حصلت عليها الشركة، و أي توصيات أو مراجعات إيجابية من العملاء السابقين.`,
    breakPoints: [
      {
        title: `يبني سابقة الأعمال الثقة و المصداقية الملموسة.`,
      },
      {
        title: `يعمل كأداة تسويقية قوية تساعد في جذب العملاء الجدد وزيادة قاعدة العملاء.`,
      },
      {
        title: `مواجهة المنافسة في سوق الأعمال التنافسي يعد أداة مهمة للتفرقة عن المنافسين.`,
      },
    ],
  },
];

export {
  WelcomeMessage,
  WhyUsData,
  servicesData,
  SocialMedia,
  ContactInfo,
  servicesIntro,
};
