import classes from './AllServices.module.css';
import { servicesIntro, servicesData } from '../../../Data/Data';
import { useState, useContext, useEffect } from 'react';
import { ServiceIdContext } from '../../../Context/service-id-context';
import ServiceDetails from './ServiceDetails';
import ServicesIconList from './ServiceIconList';
import IconListPreviewCircles from './IconListPreviewCircles';

function AllServices(props) {
  const serviceIdCtx = useContext(ServiceIdContext);
  const [serviceId, setServiceId] = useState(1);
  const [prevServiceId, setPrevServiceId] = useState(1);
  const [iconIndex, setIconIndex] = useState(0);
  const [offsetIconIndex, setOffsetIconIndex] = useState(4);

  const [serviceDetailsTransition, setServiceDetailsTransition] = useState();

  const [transitionDirection, setTransitionDirection] = useState('');
  const [transitionIndex, setTransitionIndex] = useState('');

  const handleServiceChange = newServiceId => {
    setPrevServiceId(serviceId);
    setServiceId(newServiceId);
  };
  const handleServiceIconListChange = newServiceIconListId => {
    setIconIndex(newServiceIconListId);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 870) {
        setOffsetIconIndex(2);
      } else {
        setOffsetIconIndex(4);
      }
    };

    handleResize();
  }, []);
  useEffect(() => {
    setServiceId(serviceIdCtx);
  }, [serviceIdCtx]);

  const HandleServiceTransition = () => {
    // Go to left transition
    setServiceDetailsTransition(
      <div className={`${classes.GoToLeftServicesListTransition}`}>
        <ServiceDetails serviceId={prevServiceId} />
      </div>
    );

    // After a delay, come from right transition
    setTimeout(() => {
      setServiceDetailsTransition(
        <div className={` ${classes.ComesFromRightServicesListTransition}`}>
          <ServiceDetails serviceId={serviceId} />
        </div>
      );
    }, 300);
  };
  const handleButtonClick = direction => {
    setTransitionDirection(direction);
    setTimeout(() => {
      setTransitionDirection('');
    }, 300);
  };
  useEffect(() => {
    HandleServiceTransition();
  }, [serviceId]);

  return (
    <div className={classes.Body} ref={props.allServicesRef}>
      {/* intro */}
      <div className={classes.Intro}>
        <h1>{servicesIntro.title}</h1>
        <p>{servicesIntro.description}</p>
      </div>
      {/* services */}
      <div className={classes.ContentBox}>
        {/* services icon list */}
        <div className={classes.SCrollableIconContainer}>
          <div
            className={classes.scrollButton}
            onClick={() => {
              const newIconIndex =
                (iconIndex - 1 + servicesData.length) % servicesData.length;
              handleServiceIconListChange(newIconIndex);
              handleButtonClick('left');
              setTransitionIndex(newIconIndex);
            }}
          >
            {'<'}
          </div>
          <div className={classes.IconListContainer}>
            <ServicesIconList
              iconIndex={iconIndex}
              servicesData={servicesData}
              serviceId={serviceId}
              offsetIconIndex={offsetIconIndex}
              handleServiceChange={circularIndex => {
                handleServiceChange(servicesData[circularIndex].id);
              }}
              transitionDirection={transitionDirection}
              transitionIndex={transitionIndex}
            />
            <IconListPreviewCircles
              iconIndex={iconIndex}
              servicesData={servicesData}
              offsetIconIndex={offsetIconIndex}
              selectedServiceIndex={serviceId - 1}
            />
          </div>
          <div
            className={classes.scrollButton}
            onClick={() => {
              const newIconIndex =
                (iconIndex + 1 + servicesData.length) % servicesData.length;
              handleServiceIconListChange(newIconIndex);
              handleButtonClick('right');
              setTransitionIndex(
                (iconIndex + offsetIconIndex) % servicesData.length
              );
            }}
          >
            {'>'}
          </div>
        </div>
        {/* service details */}
        {serviceDetailsTransition}
      </div>
    </div>
  );
}

export default AllServices;
