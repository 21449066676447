import classes from './Welcome.module.css';

import { logo, logoGif } from '../../../Assets';
import { browserName } from 'react-device-detect';

import { WelcomeMessage, SocialMedia, ContactInfo } from '../../../Data/Data';

function Welcome(props) {
  const isMobile = /Mobi/i.test(navigator.userAgent);
  return (
    <div className={classes.Body} ref={props.welcomeRef}>
      <div className={classes.HeaderSection}>
        {/* email and phone */}
        <div className={classes.ContactInfo}>
          {ContactInfo.map(data => {
            return (
              <a className={classes.SingleInfo} href={data.path}>
                <img src={data.icon} alt={data.title} />
                <p>{data.content}</p>
              </a>
            );
          })}
        </div>
        {/* social media */}
        <div className={classes.LeftContainer}>
          <div className={classes.SocialMedia}>
            {SocialMedia.map(data => {
              return (
                <a
                  href={data.link}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={classes.SingleLink}
                >
                  <img src={data.iconLight} alt={data.title} />
                </a>
              );
            })}
          </div>
          {/* logo */}
          <div className={classes.Logo}>
            <img src={logo} alt='Logo' />
          </div>
        </div>
      </div>
      <div className={classes.DetailsSection}>
        {/* text */}
        <div className={classes.ContentBox}>
          {/* logo */}
          <div className={classes.ContentBoxLogo}>
            {browserName !== 'Chrome' || isMobile ? (
              <img src={logoGif} alt='Logo GIF' />
            ) : (
              <img src={logo} alt='Logo' />
            )}
          </div>
          {/* text */}
          <div className={classes.Content}>
            <h1>{WelcomeMessage.heading}</h1>
            <p>{WelcomeMessage.paragraph}</p>
          </div>
        </div>
        {/* nav buttons */}
        <div className={classes.NavButtons}>
          {/* button */}
          <button
            onClick={() => {
              props.scrollToAllServices();
            }}
          >
            Services
          </button>
          <button
            onClick={() => {
              props.scrollToWhyUsRef();
            }}
          >
            Why Us
          </button>
        </div>
      </div>
    </div>
  );
}
export default Welcome;
